<template>
  <b-modal
    :id="modalId"
    @hide="onHide"
    size="lg"
    header-class="creator-modal__header"
    hide-footer
  >
    <template #modal-title>
      <span class="flex-grow-1 w-100">{{ title }} ADD POST </span>
    </template>

    <template #modal-header-close>
      <b-button variant="primary" @click.stop="confirmModal">POST</b-button>
    </template>

    <b-form>
      <div class="mb-1 position-absolute centered-element">
        <b-spinner label="Loading..." v-if="requestInProgress" />
      </div>

      <b-row>
        <b-col>
          <b-form-group>
            <label for="datepicker-placeholder">User <sup>*</sup></label>

            <v-select
              v-model="form.user_id"
              label="name"
              :options="searchedUsersList"
              :reduce="(option) => option.id"
              @search="handleUserSearch"
            />
          </b-form-group>

          <b-row>
            <b-col cols="6">
              <ui-media-uploader
                :class="{ 'disabled-video-uploader': !form.user_id }"
                :key="media.length"
                v-model="media"
                :is-flow="true"
                :user-id="form.user_id"
                ref="uploader"
                @change="mediaChange"
                @change-upload="mediaClick"
              />

              <div>
                <div>
                  <input
                    :ref="`videoInput${postId || ''}`"
                    type="file"
                    :accept="mediaTypes"
                    @change="uploadFromDevice"
                    hidden
                  />
                </div>
              </div>
            </b-col>

            <b-col cols="6">
              <b-form-group label="NSFW">
                <b-form-radio-group v-model="form.is_nsfw">
                  <b-form-radio
                    v-for="nsfwOption in nsfwOptions"
                    :key="nsfwOption.id"
                    :value="nsfwOption.value"
                  >
                    {{ nsfwOption.label }}
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <small class="text-danger" v-show="errors.has('media')">
                {{ errors.first("media") }}
              </small>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Caption" label-for="caption">
                <b-form-textarea
                  v-model="form.message"
                  type="text"
                  name="caption"
                  placeholder="Creator caption"
                  v-validate="'required'"
                />

                <small class="text-danger" v-show="errors.has('caption')">
                  {{ errors.first("caption") }}
                </small>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-checkbox-group v-model="form.categories">
            <p>Categories (select up to 2)</p>

            <b-form-checkbox
              v-for="raceOption in settingsCategories"
              :key="raceOption.id"
              :value="raceOption.id"
            >
              {{ raceOption.name }}
            </b-form-checkbox>
          </b-form-checkbox-group>

          <small class="text-danger" v-show="errors.has('categories')">
            {{ errors.first("categories") }}
          </small>
        </b-col>

        <b-col>
          <b-form-group label="Race(s)">
            <b-form-checkbox-group v-model="form.metadata.races">
              <b-form-checkbox
                v-for="raceOption in metadataOptions.races"
                :key="raceOption.id"
                :value="raceOption.id"
              >
                {{ raceOption.label }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>

          <b-form-group label="Hair Color">
            <b-form-checkbox-group v-model="form.metadata.hair_color">
              <b-form-checkbox
                v-for="hairColorOption in metadataOptions.hair_color"
                :key="hairColorOption.id"
                :value="hairColorOption.id"
              >
                {{ hairColorOption.label }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>

          <b-form-group label="Eye Color">
            <b-form-checkbox-group v-model="form.metadata.eye_color">
              <b-form-checkbox
                v-for="eyeColorOption in metadataOptions.eye_color"
                :key="eyeColorOption.id"
                :value="eyeColorOption.id"
              >
                {{ eyeColorOption.label }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>

          <b-form-group label="Additional Tags" label-for="tags">
            <b-form-tags
              class="creator-tag"
              input-id="tags"
              v-model="form.metadata.additionalTags"
              label="tags"
              name="metadata.tags"
              data-vv-as="tags"
              tag-pills
              size="lg"
              remove-on-delete
            />

            <small class="text-danger" v-show="errors.has('metadata.tags')">
              {{ errors.first("metadata.tags") }}
            </small>
          </b-form-group>

          <b-form-group label="Description" label-for="description">
            <b-form-textarea
              v-model="form.description"
              type="text"
              name="description"
              placeholder="Creator description"
              v-validate="'required'"
            />

            <small class="text-danger" v-show="errors.has('description')">
              {{ errors.first("description") }}
            </small>
          </b-form-group>

          <div>
            <p>Generated Metadata (for Recommendation)</p>
            <template v-for="tagArr in tags">
              <b-badge
                v-for="(tag, index) in tagArr"
                :key="tag + index"
                pill
                variant="warning"
                class="mr-1"
              >
                {{ tag }} </b-badge
              >&nbsp;
            </template>
          </div>

          <p
            v-if="postId"
            role="button"
            class="delete-acc mt-3 ml-0"
            @click="deleteContentHandler"
          >
            Delete Content
          </p>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import axios from "axios";
import settings from "@/mixins/settings";
import {
  availableImagesTypes,
  availableVideoTypes,
  flowVideoMaxLength,
} from "@/config/video";
import UiMediaUploader from "@/features/containers/post-create/components/UiMediaUploader.vue";
import mux from "@/mixins/mux";
import vSelect from "vue-select";
import { debounce } from "lodash";
import { cleanObj } from "@/tools/helpers";
import { mapMutations } from "vuex";

export default {
  name: "CreatorModals",
  components: { UiMediaUploader, vSelect },
  mixins: [waitRequest, validationError, settings, mux],
  props: {
    modalId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    postId: {
      type: [String, Number],
      default: undefined,
    },
    tableType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      post: null,
      searchTerm: "",
      showDropdown: false,
      media: [],
      mediaTypes: availableVideoTypes.toString(),
      searchedUsersList: [],

      form: {
        is_nsfw: false,
        message: "",
        description: "",
        user_id: "",
        categories: [],

        metadata: {
          races: ["white"],
          hair_color: ["black"],
          eye_color: ["brown"],
          additionalTags: [],
        },
      },

      nsfwOptions: [
        { label: "Yes", id: 1, value: true },
        { label: "No", id: 0, value: false },
      ],

      metadataOptions: {
        eye_color: [],
        hair_color: [],
        races: [],
      },
    };
  },
  computed: {
    availableImagesTypes() {
      return availableImagesTypes;
    },
    tags() {
      let tags = [];
      const { hair_color, eye_color, races } = this.form.metadata;

      const tagsList = [
        ["hair", hair_color],
        ["eye", eye_color],
        ["races", races],
      ].map(([key, tags]) => {
        if (!tags.length) return [];

        if (Array.isArray(tags)) {
          return tags?.map((tag) => {
            if (tag?.includes("_")) {
              const tagItem = tag?.split("_").join("-");
              return `${tagItem}-${key}`;
            }

            return `${tag}-${key}`;
          });
        }

        return [`${tags}-${key}`];
      });

      tags.push(...tagsList);

      tags.push([...this.form.metadata.additionalTags]);

      return tags;
    },
  },

  watch: {
    postId(id) {
      if (id) this.fetchPost(id);
    },
  },

  mounted() {
    this.initState();
  },

  updated() {
    const ref = this.$refs?.[`videoInput${this.postId || ""}`];

    if (ref) {
      this.setupMuxInput(ref);
    }
  },

  methods: {
    ...mapMutations({
      deleteContent: "contents/deleteContent",
    }),
    // modal methods
    async initState() {
      await this.getSettingsMetadata();
      await this.getSettingsSex();
      await this.getSettingsCategories();

      this.setOptionsSettings();
    },

    setOptionsSettings() {
      this.sexOrientationOptions = Object.entries(
        this.sexSettings.orientation
      ).map(([id, label]) => ({ id, label }));

      this.sexOptions = Object.entries(this.sexSettings.sex)
        .map(([id, label]) => ({ id, label }))
        .slice(0, 2); // only woman and man

      for (let key in this.metadataSettings) {
        let arr = [];
        for (let metaKey in this.metadataSettings[key]) {
          arr.push({ id: metaKey, label: this.metadataSettings[key][metaKey] });
        }

        this.metadataOptions[key] = arr;
        arr = [];
      }
    },

    hideModal() {
      this.$bvModal.hide(this.modalId);
    },

    initFormData() {
      this.errors = [];
      if (!this.postId) return;
      this.searchedUsersList = [this.post.user];
      this.media = this.post.media;

      const categories = this.settingsCategories
        .filter((item) =>
          this.post?.categories?.find((category) => category.id === item.id)
        )
        .map((item) => item.id);

      const {
        message,
        description,
        is_nsfw,
        metadata: {
          race = [""],
          hair_color = [""],
          eye_color = [""],
          tags = [""],
        } = {},
      } = this.post;

      this.form = {
        message,
        description,
        is_nsfw,
        user_id: this.post?.user?.id,
        categories,

        metadata: {
          races: race,
          hair_color,
          eye_color,
          additionalTags: tags,
        },
      };
    },

    resetFormData() {
      this.media = [];

      this.form = {
        is_nsfw: false,
        message: "",
        description: "",
        user_id: "",
        categories: [],

        metadata: {
          races: ["white"],
          hair_color: ["black"],
          eye_color: ["brown"],
          additionalTags: [],
        },
      };
    },

    // send form
    async confirmModal() {
      await this.$validator.reset();
      const isValid = await this.$validator.validate();

      if (this.requestInProgress || !isValid) return;

      const media = this.media?.map((mediaItem) => ({
        id: mediaItem.id,
        screenshot: 0,
      }));

      const hasMedia = this.media[0]?.created_at;
      if (!hasMedia) {
        this.$validator.errors.add({
          field: "media",
          msg: "No media files or media files are still loaded",
        });

        return;
      }

      const { hair_color, eye_color, races, additionalTags } =
        this.form.metadata;

      const metadata = {
        race: races,
        hair_color,
        eye_color,
        tags: [...additionalTags],
      };

      const formData = {
        ...this.form,
        metadata,
        media,
      };

      if (this.postId) return this.updatePost(formData, this.postId);

      this.createPost(formData);
    },

    createPost(formData) {
      const url = "/parodies/posts";

      return this.waitRequest(() => {
        return axios({
          method: "POST",
          url: process.env.VUE_APP_API_URL + "/admin" + url,
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
          data: formData,
        })
          .then((response) =>
            this.onHide({ type: "create", content: response.data.post })
          )
          .catch((error) => {
            if (error?.response?.data.error) {
              this.$validator.errors.add({
                field: "media",
                msg: error?.response?.data?.message,
              });
            } else {
              this.checkErrors(error);
            }
          });
      });
    },

    updatePost(formData, id) {
      const url = `/parodies/posts/${id}`;

      return this.waitRequest(() => {
        return axios({
          method: "PATCH",
          url: process.env.VUE_APP_API_URL + "/admin" + url,
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
          data: formData,
        })
          .then((response) =>
            this.onHide({ type: "edit", content: response.data.post })
          )
          .catch(this.checkErrors);
      });
    },

    successConfirmModal(data) {
      this.onHide("create");
      console.log("data", data);
    },

    failureConfirmModal(error) {
      console.log("error", error);
    },

    onHide(data) {
      this.$emit("hide", data);
      this.resetFormData();
    },

    mediaChange() {},

    // form methods
    deleteContentHandler() {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete everything.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "danger",
          modalClass: "modal-danger",
          okTitle: "Yes",
          cancelTitle: "No",
          headerClass: "creator-modal__header",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.delete();
          }
        });
    },

    // image uplader methods
    handleFileUpload(event) {
      const name = event.target.name;
      const file = event.target.files[0];
      this.form[name] = file;

      this.createImagePreview(file, name);
    },

    createImagePreview(file, name) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.preview[name] = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    addImage(type) {
      this.$refs[type].click();
    },

    deleteImage(type) {
      this.form[type] = "";
      this.preview[type] = "";
    },

    mediaClick() {
      // this.clearInputData()
      this.$refs?.[`videoInput${this.postId || ""}`].click();
      this.setUser(this.form.user_id);
    },
    isFlowDataValid(fields) {
      const video = fields.media?.[0];
      const hasVideo = Boolean(video?.meta?.length);
      if (!hasVideo) {
        this.$popup.open(
          "ErrorPopup",
          {
            title: "Video is required",
            message: "Please add video",
          },
          2
        );

        return false;
      }

      const isVideoToLong = video.meta.length;
      if (isVideoToLong > flowVideoMaxLength) {
        this.$popup.open(
          "ErrorPopup",
          {
            title: "Flow video to long",
            message: `Current video length ${video.meta.length}s. Must be shorter then ${flowVideoMaxLength} seconds. Please change video`,
          },
          2
        );

        return false;
      }

      return true;
    },

    uploadFromDevice(uploadEvent) {
      const file = uploadEvent?.target?.files[0];

      const isVideoFile = availableVideoTypes.includes(file?.type);
      if (isVideoFile) {
        this.muxUploadVideo(file);
        return;
      }

      const isImages = availableImagesTypes.includes(file?.type);
      if (isImages) {
        this.$refs.uploader.submitFile(file);
        return;
      }

      this.$refs.file.value = "";
      this.$popup.open(
        "ErrorPopup",
        {
          title: `Wrong file type format: ${file.type}`,
          message: `
        <br> available video types is ${availableVideoTypes.toString()}
        <br><br> available images types is ${availableImagesTypes.toString()}`,
        },
        2
      );
    },

    // post
    fetchPost(id) {
      return this.waitRequest(() => {
        const url = `/parodies/posts/${id}`;

        return this.$aGet(
          url,
          (data) => {
            this.post = data.post;
            this.initFormData();
          },
          (error) => console.log(error),
          this.checkErrors
        );
      });
    },

    delete() {
      if (this.requestInProgress) return;
      const url = `/parodies/posts/${this.postId}`;

      return this.waitRequest(() => {
        return axios({
          method: "DELETE",
          url: process.env.VUE_APP_API_URL + "/admin" + url,
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
          .then(() => {
            this.deleteContent({
              id: this.postId,
              type: this.tableType,
            });
            this.onHide({ type: "edit" });
          })
          .catch(this.checkErrors);
      });
    },

    // user search
    handleUserSearch(user) {
      if (!user) return;

      this.searchUser(user);
    },

    searchUser: debounce(function (search) {
      return this.waitRequest(() => {
        return this.fetchSearchedUsers(search)
          .then((response) => {
            this.searchedUsersList = response.data?.data || [];
          })
          .catch(this.checkErrors);
      });
    }, 500),

    fetchSearchedUsers(search) {
      const url = `/parodies/search`;
      const params = cleanObj({ q: search });

      return axios({
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/admin" + url,
        headers: {
          Authorization: "Bearer " + this.$store.state.token,
        },
        params,
      });
    },
  },
};
</script>

<style lang="scss">
.cover,
.avatar {
  z-index: 2;

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0.2;
    pointer-events: none;
    content: "";
    z-index: 3;
  }
}

.cover {
  height: 92px;

  img {
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.buttons {
  position: absolute;
  bottom: 0%;
  right: 0%;
  //transform: translate(-50%, -50%);
  z-index: 11;
}

.avatar {
  height: 92px;
  width: 92px !important;
  margin-top: -50px;
  z-index: 3;
  &:after {
    border-radius: 50%;
  }
}

.delete-acc {
  margin-left: 1rem;
  color: red;
  bottom: 0;
  left: 0;
}

.disabled-video-uploader {
  pointer-events: none;
  filter: grayscale(1);
}
</style>
