<template>
  <b-row class="users">
    <b-col>
      <b-row class="border-bottom sticky-top page-header bg-white">
        <div class="d-flex align-items-center w-100 mx-3">
          <h5 class="text-uppercase p-0 my-3 mr-2">
            {{ $t("admin.contentPage.title") }}
          </h5>

          <b-nav pills>
            <b-nav-item
              v-for="(tab, index) in tabs"
              :key="tab + index"
              :active="activeTab === tab.id"
              @click="activeTab = tab.id"
            >
              {{ tab.name }}
            </b-nav-item>
          </b-nav>

          <b-button
            class="text-uppercase p-1 ml-auto w-auto"
            @click="showCreatorModal"
          >
            {{ $t("admin.contentPage.addButton") }}
          </b-button>
        </div>
      </b-row>

      <b-row>
        <div class="mb-1 position-absolute centered-element">
          <b-spinner label="Loading..." v-if="requestInProgress" />
        </div>

        <b-table
          :key="activeTab + key"
          id="table"
          hover
          ref="table"
          responsive
          :items="activeTable.options"
          :fields="someFields"
          :per-page="activeTable.limit"
          class="small nowrap"
          :current-page="activeTable.page"
        >
          <template #cell(user)="{ item } = {}">
            <b-avatar :src="item.avatar" :to="item.name" size="sm" />
            <CLink v-if="item.username" :to="`/${item.username}`"> {{ item.name }}</CLink>
          </template>

          <template #cell(preview)="{ item } = {}">
            <b-row>
              <b-col cols="12">
                <router-link :to="'/' + item.user.username">
                  <div class="preview bg-light rounded">
                    <img class="preview__image" :src="getScreenshot(item)" />
                  </div>
                </router-link>
              </b-col>

              <b-col cols="4" class="pr-0 mt-1">
                <router-link :to="'/' + item.user.username">
                  <b-avatar
                    v-if="item.user"
                    :src="item.user.avatar"
                    text=" "
                    size="25px"
                    class="user-avatar"
                  />
                </router-link>
              </b-col>

              <b-col cols="8" class="mt-1 d-flex align-items-center">
                <router-link :to="'/' + item.user.username">
                  <span> {{ item.user.username }}</span>
                </router-link>
              </b-col>
            </b-row>
          </template>

          <template #cell(metadata)="{ item } = {}">
            <span class="d-flex flex-wrap">
              <template v-for="tags in item.metadata">
                <b-badge
                  v-for="(tag, index) in tags"
                  :key="tags + index"
                  pill
                  variant="warning"
                  class="mr-1 mb-1"
                >
                  {{ tag }}
                </b-badge>
                &nbsp;
              </template>
            </span>
          </template>

          <template #cell(categories)="{ item } = {}">
            <span class="d-flex flex-wrap">
              <template v-for="tag in item.categories">
                <b-badge :key="tag.id" pill variant="primary" class="mr-1 mb-1">
                  {{ tag.name }}
                </b-badge>
                &nbsp;
              </template>
            </span>
          </template>

          <template #cell(caption)="{ item } = {}">
            <div class="text-cell">{{ item.message }}</div>
          </template>

          <template #cell(description)="{ item } = {}">
            <div class="text-cell">{{ item.description }}</div>
          </template>

          <template #cell(status)="{ item } = {}">
            <b-badge :variant="accountStatus(item.parody_status).theme" class="mr-1 mb-1">
              {{ accountStatus(item.parody_status).text }}
            </b-badge>
          </template>

          <template #cell(social_networks)="{ item } = {}">
            <span v-if="Object.keys(item.social_networks).length">
              <template
                v-for="[icon, link] in Object.entries(item.social_networks)"
              >
                <a :key="icon" :href="link" target="_blank" v-if="link">
                  <CIcon
                    class="social-icon"
                    :name="icon"
                    path="admin/creator"
                  />
                </a>
              </template>
            </span>
          </template>

          <template #cell(actions)="{ item } = {}">
            <div>
              <b-row clas="p-1 mr-1">
                <b-col
                  cols="12"
                  class="p-1"
                  v-if="activeTab === contentsTypes.pending"
                >
                  <b-button
                    v-if="canModerate"
                    size="sm"
                    @click="
                      changeModerationStatus(item, moderationStatus.active)
                    "
                    class="mb-1"
                    variant="primary"
                    :disabled="item.parody_status == moderationStatus.active"
                  >
                    {{ $t("admin.contentPage.buttonsAction.approve") }}
                  </b-button>
                </b-col>

                <b-col class="px-1">
                  <b-button
                    size="sm"
                    @click="editContentModal(item.id)"
                    class="p-0 mb-1"
                    variant="secondary"
                  >
                    {{ $t("admin.contentPage.buttonsAction.edit") }}
                  </b-button>
                </b-col>

                <b-col class="px-1">
                  <b-button
                    v-if="activeTab !== contentsTypes.rejected && canModerate"
                    size="sm"
                    @click="
                      changeModerationStatus(item, moderationStatus.rejected)
                    "
                    class="p-0 mb-1"
                    variant="danger"
                    :disabled="item.parody_status == moderationStatus.rejected"
                  >
                    {{ $t("admin.contentPage.buttonsAction.reject") }}
                  </b-button>

                  <b-button
                    v-else-if="canModerate"
                    size="sm"
                    @click="
                      changeModerationStatus(item, moderationStatus.active)
                    "
                    class="p-0"
                    variant="primary"
                    :disabled="item.parody_status == moderationStatus.active"
                  >
                    {{ $t("admin.contentPage.buttonsAction.approve") }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </template>
        </b-table>
      </b-row>

      <b-row class="text-center m-3 d-block" v-if="activeTable.count">
        <b-pagination
          v-model="tabPagination[activeTab]"
          :total-rows="activeTable.count"
          :per-page="activeTable.limit"
          class="mt-1 mb-0"
          last-number
          @change="changePage"
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          first-number
        />
      </b-row>
    </b-col>

    <ContentsModals
      :modalKey="modalKeymodalKey"
      :modal-id="`content-modal`"
      @hide="onHide"
      v-bind="{ postId: contentId, tableType: this.activeTab }"
    />
  </b-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { cleanObj } from "@/tools/helpers";
import validationError from "@/mixins/validationError";
import waitRequest from "@/mixins/waitRequest";
import settings from "@/mixins/settings";

import ContentsModals from "@/components/common/ContentsModals.vue";
import CLink from "@/features/ui/CLink.vue";
import CIcon from "@/features/ui/CIcon.vue";
import axios from "axios";

const contentsTypes = {
  pending: "1",
  rejected: "2",
  active: "3",
};

const moderationStatus = {
  pending: 1,
  rejected: 2,
  active: 3,
};
export default {
  name: "PageContent",
  components: {
    CLink,
    ContentsModals,
    CIcon,
  },
  mixins: [validationError, waitRequest, settings],
  data() {
    return {
      modalKeymodalKey: Math.random(),
      contentsTypes,
      moderationStatus,
      contentId: null,
      type: null,

      searchTerm: "",

      someFields: [
        {
          key: "id",
          label: this.$t("admin.contentPage.table.pending.id"),
          tdClass: "full",
        },
        {
          key: "preview",
          label: this.$t("admin.contentPage.table.pending.preview"),
          tdClass: "full",
        },
        {
          key: "caption",
          label: this.$t("admin.contentPage.table.pending.caption"),
          tdClass: "full",
        },
        {
          key: "description",
          label: this.$t("admin.contentPage.table.pending.description"),
          tdClass: "full",
        },
        {
          key: "categories",
          label: this.$t("admin.contentPage.table.pending.categories"),
          tdClass: "full",
        },
        {
          key: "metadata",
          label: this.$t("admin.contentPage.table.pending.metadata"),
          tdClass: "full",
        },
        {
          key: "status",
          label: this.$t("admin.contentPage.table.pending.status"),
          tdClass: "full",
        },
        {
          key: "actions",
          label: this.$t("admin.contentPage.table.pending.actions"),
          tdClass: "full",
        },
      ],

      activeTab: contentsTypes.pending,

      tabs: [
        { id: contentsTypes.pending, name: "PENDING APPROVAL" },
        { id: contentsTypes.rejected, name: "REJECTED" },
        { id: contentsTypes.active, name: "ACTIVE" },
      ],

      tabPagination: {
        [contentsTypes.pending]: 1,
        [contentsTypes.rejected]: 1,
        [contentsTypes.active]: 1,
      },
    };
  },

  computed: {
    ...mapGetters({
      contents: "contents/contents",
    }),

    canModerate() {
      return this.$store.state.currentUser.canModerate;
    },

    tables() {
      const pending = this.contents[contentsTypes.pending];
      const rejected = this.contents[contentsTypes.rejected];
      const active = this.contents[contentsTypes.active];

      return {
        [contentsTypes.pending]: {
          columns: pending,
          options: pending.value,
          count: pending.count,
          limit: pending.limit,
          page: pending.page,
        },
        [contentsTypes.rejected]: {
          columns: rejected,
          options: rejected.value,
          count: rejected.count,
          limit: rejected.limit,
          page: rejected.page,
        },
        [contentsTypes.active]: {
          columns: active,
          options: active.value,
          count: active.count,
          limit: active.limit,
          page: active.page,
        },
      };
    },

    activeTable() {
      return this.tables[this.activeTab];
    },
  },

  mounted() {
    this.initState();
  },

  methods: {
    ...mapMutations({
      setContents: "contents/setContents",
      updateContentsList: "contents/updateContentsList",
      addToContentsList: "contents/addToContentsList",
      deleteContent: "contents/deleteContent",
    }),

    async initState() {
      await this.getSettingsCategories();
      await this.fetchContents({ page: 1, status: contentsTypes.pending });
      await this.fetchContents({ page: 1, status: contentsTypes.rejected });
      await this.fetchContents({ page: 1, status: contentsTypes.active });
    },

    accountStatus(status) {
      const statusColorVariant = {
        1: "primary",
        2: "danger",
        3: "success",
      };
      return {
        text: this.settings?.parody_account_statuses?.[status] || "Pending",
        theme: statusColorVariant[status],
      };
    },

    showCreatorModal() {
      this.modalKeymodalKey = Math.random();
      this.$bvModal.show("content-modal");
    },

    editContentModal(contentId) {
      this.contentId = contentId;
      this.type = this.activeTab;
      this.$bvModal.show(`content-modal`);
    },

    onHide(data) {
      this.contentId = null;
      this.type = null;

      if (!data.user) this.$bvModal.hide("content-modal");

      if (data.type === "create") {
        this.addToContentsList({ contents: data.content });
      }

      if (data.type === "edit") {
        this.updateContentsList({
          content: data.content,
          type: data.content.parody_status,
        });
      }

      this.$bvModal.hide("creator-modal");
    },

    getScreenshot(item) {
      const screenshot = item?.media?.[0].screenshot || {};
      const { url = "", jwt = "" } = screenshot;

      return `${url}?token=${jwt}`;
    },

    createQueryConfig(config) {
      return {
        page: this.activeTable.page,
        search: this.searchTerm || undefined,
        status: this.activeTab || undefined,

        ...config,
      };
    },

    changePage(pageNumber) {
      if (this.requestInProgress) return;
      const query = this.createQueryConfig({ page: pageNumber });

      return this.fetchCreator(query);
    },

    fetchContents(query) {
      if (this.requestInProgress) return;

      const url = "/parodies/posts/list";
      const params = cleanObj(query);

      return this.waitRequest(() => {
        return this.$aGet(
          url,
          (data) => {
            this.setContents({ contents: data, params: query });
          },
          console.log,
          this.checkErrors,
          params
        ).catch(this.checkErrors);
      });
    },

    changeModerationStatus(post, status) {
      if (this.requestInProgress) return;

      const url = `/parodies/posts/moderation/${post.id}/${status}`;

      return this.waitRequest(() => {
        return axios({
          method: "POST",
          url: process.env.VUE_APP_API_URL + "/admin" + url,
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
          .then((response) => {
            this.updateContentsList({
              content: response.data?.post,
              type: this.activeTab,
              status,
            });

            this.deleteContent({
              id: response.data?.post?.id,
              type: this.activeTab,
            })

            this.key = Math.random();
          })
          .catch(this.checkErrors);
      });
    },
  },
};
</script>

<style lang="scss">
.social-icon {
  width: 20px;
  height: 20px;
  margin-right: 1rem;
}

.centered-element {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.preview {
  flex: 0 0 92px;
  width: 92px;
  height: 92px;
  position: relative;
  line-height: 1;
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;

  &__image {
    width: 92px;
    height: 92px;
    object-fit: cover !important;
  }
}

.user-avatar {
  //min-width: em(25);
}

.text-cell {
  display: block;
  min-width: 300px;
  white-space: normal;
}
</style>
